<template>
    <form class="l-stack l-gap-3" @submit.prevent="handleSubmit">
        <div class="l-stack l-gap-2">
            <MeasurementSelect
                v-model="condition"
                :options="conditionOptions"
                :placeholder="$t('conditionPlaceholder')"
                @input="clearValue"
            />

            <BaseMultiselect
                v-if="condition === 'battery_status'"
                v-model="value"
                :options="batteryStatusOptions"
                :custom-label="
                    option =>
                        $t(`shared.measurementOptions.${condition}.${option}`)
                "
                :placeholder="$t('value')"
            />

            <BaseMultiselect
                v-if="condition === 'button_mode'"
                v-model="value"
                :options="buttonModeOptions"
                :custom-label="
                    option =>
                        $t(`shared.measurementOptions.${condition}.${option}`)
                "
                :placeholder="$t('value')"
            />

            <div v-else-if="condition === 'dtc_codes'">
                {{ $t('dtcCodesAvailable') }}
            </div>

            <BaseMultiselect
                v-else-if="condition === 'ev_charging_state'"
                v-model="value"
                :options="stateOptions"
                :custom-label="option => $t(option)"
                :searchable="false"
                :placeholder="$t('value')"
            />

            <div
                v-else-if="condition === 'geofence'"
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <BaseMultiselect
                    v-model="operator"
                    :options="geofenceOptions"
                    :custom-label="option => $t(option)"
                    :searchable="false"
                    :placeholder="$t('position')"
                />

                <BaseMultiselect
                    v-model="value"
                    :options="locationsSortedByName"
                    track-by="id"
                    label="name"
                    :placeholder="$t('location')"
                />
            </div>

            <div v-else-if="condition === 'maintenance'">
                {{ $t('maintenanceNeeded') }}
            </div>

            <template
                v-else-if="condition === 'offline' || condition === 'online'"
            >
                <BaseInput
                    v-model.number="timeValue"
                    class="l-full-width"
                    type="number"
                    step="any"
                    :placeholder="$t('value')"
                >
                    <template #prefix>
                        {{
                            condition === 'offline'
                                ? $t('moreThan')
                                : $t('within')
                        }}
                    </template>

                    <template #postfix>
                        {{ $t('time-h') }}
                    </template>
                </BaseInput>

                <BaseMultiselect
                    v-if="hasAdvancedRuleAccess"
                    v-model="gateways"
                    :placeholder="$t('gateways')"
                    :options="trackers"
                    track-by="id"
                    :custom-label="option => option.asset_details.name"
                    multiple
                />
            </template>

            <template v-else-if="condition === 'regex'">
                <BaseInput
                    v-model="$v.name.$model"
                    :placeholder="$t('customConditionName')"
                />

                <BaseInput v-model="value" :placeholder="$t('value')" />
            </template>

            <div
                v-else-if="
                    condition === 'temperature' || isTemperature(condition)
                "
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <BaseMultiselect
                    v-model="operator"
                    :options="operatorOptions"
                    :custom-label="option => operatorTranslations[option]"
                    :searchable="false"
                    :placeholder="$t('operator')"
                />

                <BaseInput
                    v-model.number="$v.value.$model"
                    type="number"
                    step="any"
                    :placeholder="$t('value')"
                >
                    <template #postfix>
                        {{ units.temperature }}
                    </template>
                </BaseInput>
            </div>

            <template v-else-if="condition === 'weekday_schedule'">
                <div
                    v-for="(item, i) in value"
                    :key="i"
                    class="l-inline l-gap-1"
                >
                    <BaseMultiselect
                        v-model="item.weekday"
                        :allow-empty="false"
                        :options="weekdayOptions"
                        :custom-label="
                            option => $t(`shared.weekdays.${option}`)
                        "
                        :placeholder="$t('weekday')"
                    />

                    <TimeInput v-model="item.start" />

                    <TimeInput v-model="item.end" />

                    <IconButton
                        @click.prevent="
                            value = [
                                ...value.slice(0, i),
                                ...value.slice(i + 1),
                            ]
                        "
                    >
                        <RemoveIcon />
                    </IconButton>
                </div>

                <div class="l-inline l-justify-end">
                    <IconButton
                        @click.prevent="
                            value = [
                                ...(value || []),
                                { start: '00:00', end: '00:00' },
                            ]
                        "
                    >
                        <AddIcon />
                    </IconButton>
                </div>
            </template>

            <BaseMultiselect
                v-else-if="isState(condition)"
                v-model="value"
                :options="stateOptions"
                :custom-label="option => $t(option)"
                :searchable="false"
                :placeholder="$t('value')"
            />

            <template v-else-if="condition">
                <BaseInput
                    v-if="condition === 'custom'"
                    v-model="$v.name.$model"
                    :placeholder="$t('customConditionName')"
                />

                <div class="l-inline l-gap-1 l-center-v conditions-row">
                    <BaseMultiselect
                        v-model="operator"
                        :options="operatorOptions"
                        :custom-label="option => operatorTranslations[option]"
                        :searchable="false"
                        :placeholder="$t('operator')"
                    />

                    <BaseInput
                        v-model.number="$v.value.$model"
                        type="number"
                        step="any"
                        :placeholder="$t('value')"
                    >
                        <template #postfix>
                            {{ units[condition] }}
                        </template>
                    </BaseInput>
                </div>
            </template>

            <div
                v-if="
                    hasAdvancedRuleAccess &&
                        fallbackConditionOptions.includes(condition)
                "
                class="l-inline l-gap-1 l-center-v clickable"
                @click="fallbackToHistory = !fallbackToHistory"
            >
                <input :checked="fallbackToHistory" type="checkbox" />
                <p>{{ $t('fallbackToHistory') }}</p>
            </div>
        </div>

        <p
            v-show="$v.name && $v.name.$dirty && $v.name.$invalid"
            class="form-error"
        >
            {{ $t('invalidName') }}
        </p>

        <p
            v-show="$v.value && $v.value.$dirty && $v.value.$invalid"
            class="form-error"
        >
            {{ $t('invalidValue') }}
        </p>

        <div class="l-inline l-spread l-center-v">
            <span class="clickable" @click="$emit('close')">
                {{ $t('shared.cancel') }}
            </span>

            <BaseButton size="small" type="submit" :disabled="isDisabled">
                {{ $t('shared.save') }}
            </BaseButton>
        </div>
    </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'

import { measurementHelper } from '@/utils'
import AddIcon from '../icons/AddIcon'
import BaseButton from './BaseButton'
import BaseInput from './BaseInput'
import BaseMultiselect from './BaseMultiselect'
import IconButton from '../IconButton'
import MeasurementSelect from './MeasurementSelect'
import RemoveIcon from '../icons/RemoveIcon'
import TimeInput from './TimeInput'

export default {
    name: 'NotificationConditionForm',
    components: {
        AddIcon,
        BaseButton,
        BaseInput,
        BaseMultiselect,
        IconButton,
        MeasurementSelect,
        RemoveIcon,
        TimeInput,
    },
    props: {
        additionalMeasurements: {
            type: Array,
            default: () => [],
        },
        excludedMeasurements: {
            type: Array,
            default: () => [],
        },
        initialValue: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            batteryStatusOptions: ['ok', 'warning', 'critical'],
            buttonModeOptions: [1, 2, 3],
            condition: null,
            fallbackConditionOptions: [
                ...measurementHelper.measurements,
                ...measurementHelper.measurementsAnalogChannels,
                ...measurementHelper.measurementsState,
                ...measurementHelper.measurementsTemperature,
                ...measurementHelper.measurementsVoltage,
                'battery_status',
                'button_mode',
                'dtc_codes',
            ],
            conditionOptions: [
                ...measurementHelper.measurements,
                ...measurementHelper.measurementsAnalogChannels,
                ...measurementHelper.measurementsState,
                ...measurementHelper.measurementsTemperature,
                ...measurementHelper.measurementsVoltage,
                'button_mode',
                'dtc_codes',
                'geofence',
                'maintenance',
                'offline',
                'online',
                'weekday_schedule',
            ],
            fallbackToHistory: true,
            geofenceOptions: ['inside', 'outside'],
            stateOptions: ['on', 'off'],
            noOperatorConditionOptions: [
                ...measurementHelper.measurementsState,
                'battery_status',
                'button_mode',
                'dtc_codes',
                'ev_charging_state',
                'maintenance',
                'offline',
                'online',
                'regex',
                'weekday_schedule',
            ],
            operator: null,
            operatorOptions: ['<', '=', '>'],
            operatorTranslations: {
                '<': this.$t('lt'),
                '=': this.$t('eq'),
                '>': this.$t('gt'),
            },
            gateways: [],
            timeOptions: {
                h: 60 * 60,
                min: 60,
            },
            timeUnit: 'h',
            timeValue: 24,
            name: null,
            value: null,
            units: measurementHelper.units,
            weekdayOptions: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }
    },
    validations() {
        return {
            ...((this.condition === 'custom' || this.condition === 'regex') && {
                name: {
                    required,
                },
            }),
            ...(this.condition !== 'dtc_codes' &&
                this.condition !== 'maintenance' &&
                this.condition !== 'offline' &&
                this.condition !== 'online' && {
                    value: {
                        required,
                    },
                }),
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('auth', [
            'hasAdvancedRuleAccess',
            'isStaff',
            'isAdminOrStaff',
        ]),
        ...mapGetters('location', ['locationsSortedByName']),
        isDisabled() {
            return (
                this.$v.name?.$error ||
                this.$v.value?.$error ||
                !this.condition ||
                (!this.operator &&
                    !this.noOperatorConditionOptions.includes(
                        this.condition
                    )) ||
                (!this.value && !this.timeValue) ||
                (this.condition === 'weekday_schedule' &&
                    !(
                        this.value?.length &&
                        this.value.every(
                            item => item.weekday && item.start && item.end
                        )
                    ))
            )
        },
    },
    watch: {
        initialValue() {
            this.setInitialValue()
        },
    },
    mounted() {
        if (this.isStaff) {
            this.conditionOptions = [
                ...this.conditionOptions,
                ...measurementHelper.measurementsStaffOnly,
                'custom',
                'regex',
            ]
        }

        this.conditionOptions = [
            ...this.conditionOptions,
            ...this.additionalMeasurements,
        ]

        this.conditionOptions = this.conditionOptions.filter(
            option => !this.excludedMeasurements.includes(option)
        )

        this.setInitialValue()
    },
    methods: {
        clearValue() {
            this.gateways = []
            this.operator = null
            this.value = null
            this.$v.$reset()
        },
        handleSubmit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            let payload

            switch (this.condition) {
                case 'ev_charging_state':
                    payload = {
                        type: 'threshold',
                        value: this.condition,
                        threshold: this.value === 'on',
                        fallback_to_historic_data: this.fallbackToHistory,
                    }
                    break
                case 'battery_status':
                    payload = {
                        type: 'regex',
                        value: this.condition,
                        regex: this.value,
                        fallback_to_historic_data: this.fallbackToHistory,
                    }
                    break
                case 'button_mode':
                    payload = {
                        type: 'threshold',
                        operator: '=',
                        threshold: this.value,
                        value: this.condition,
                        fallback_to_historic_data: this.fallbackToHistory,
                    }
                    break
                case 'd1':
                case 'd2':
                case 'd3':
                case 'd4':
                case 'd5':
                case 'd6':
                case 'sensor_attached':
                    payload = {
                        type: 'state',
                        state: this.value === 'on',
                        value: this.condition,
                        name: 'On',
                        fallback_to_historic_data: this.fallbackToHistory,
                    }
                    break
                case 'dtc_codes':
                    payload = {
                        type: 'threshold',
                        operator: '>',
                        threshold: 0,
                        value: this.condition,
                        fallback_to_historic_data: this.fallbackToHistory,
                    }
                    break
                case 'geofence':
                    payload = {
                        type: this.condition,
                        position: this.operator,
                        locationId: this.value.id,
                    }
                    break
                case 'maintenance':
                    payload = {
                        type: this.condition,
                        ahead_value: 0,
                    }
                    break
                case 'offline':
                case 'online':
                    payload = {
                        gateways: this.gateways.length
                            ? this.gateways.map(({ asset }) => asset)
                            : null,
                        type: this.condition,
                        seconds: Math.round(
                            this.timeValue * this.timeOptions[this.timeUnit]
                        ),
                        user_unit: this.timeUnit,
                    }
                    break
                case 'regex':
                    payload = {
                        type: this.condition,
                        value: this.name,
                        regex: this.value,
                        fallback_to_historic_data: this.fallbackToHistory,
                    }
                    break
                case 'weekday_schedule':
                    payload = {
                        type: this.condition,
                        weekdays: this.value,
                    }
                    break
                default:
                    payload = {
                        type: 'threshold',
                        operator: this.operator,
                        threshold:
                            this.condition === 'fill_level'
                                ? this.value / 100
                                : this.value,
                        value:
                            this.condition === 'custom'
                                ? this.name
                                : this.condition,
                        fallback_to_historic_data: this.fallbackToHistory,
                    }
                    break
            }

            this.$emit('close', payload)
        },
        isState(value) {
            return /^(d\d|temperature|sensor_attached)$/.test(value)
        },
        isTemperature(value) {
            return /^(t\d|temperature)$/.test(value)
        },
        setInitialValue() {
            if (this.initialValue.fallback_to_historic_data !== undefined) {
                this.fallbackToHistory = this.initialValue.fallback_to_historic_data
            }

            switch (this.initialValue?.type) {
                case 'dtc_codes':
                case 'maintenance':
                    this.condition = this.initialValue.type
                    break
                case 'geofence':
                    this.condition = this.initialValue.type
                    this.operator = this.initialValue.position
                    this.value = this.locationsSortedByName.find(
                        item => item.id === this.initialValue.locationId
                    )
                    break
                case 'offline':
                case 'online':
                    this.condition = this.initialValue.type
                    this.timeUnit = this.initialValue.user_unit
                    this.timeValue =
                        this.initialValue.seconds /
                        this.timeOptions[this.timeUnit]
                    this.gateways = this.trackers.filter(({ asset }) =>
                        this.initialValue.gateways?.includes(asset)
                    )
                    break
                case 'regex':
                    if (this.initialValue.value === 'battery_status') {
                        this.condition = this.initialValue.value
                    } else {
                        this.condition = 'regex'
                        this.name = this.initialValue.value
                    }
                    this.value = this.initialValue.regex
                    break
                case 'state':
                    this.condition = this.initialValue.value
                    this.value = this.initialValue.state ? 'on' : 'off'
                    break
                case 'threshold':
                    if (this.initialValue.value === 'ev_charging_state') {
                        this.condition = this.initialValue.value
                        this.value = this.initialValue.threshold ? 'on' : 'off'
                        return
                    }
                    if (
                        this.conditionOptions.includes(this.initialValue.value)
                    ) {
                        this.condition = this.initialValue.value
                    } else {
                        this.condition = 'custom'
                        this.name = this.initialValue.value
                    }
                    this.operator = this.initialValue.operator
                    this.value =
                        this.condition === 'fill_level'
                            ? this.initialValue.threshold * 100
                            : this.initialValue.threshold
                    break
                case 'weekday_schedule':
                    this.condition = this.initialValue.type
                    this.value = cloneDeep(this.initialValue.weekdays)
                    break
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "conditionPlaceholder": "Choose Measurement",
        "customConditionName": "Name",
        "dtcCodesAvailable": "DTC Code available",
        "eq": "Equals",
        "fallbackToHistory": "Use historic data",
        "gateways": "Gateways",
        "gt": "Greater than",
        "inside": "Inside",
        "invalidName": "Invalid name",
        "invalidValue": "Invalid value",
        "location": "Location",
        "lt": "Less than",
        "maintenanceNeeded": "Maintenance is needed",
        "moreThan": "More than",
        "off": "Off",
        "on": "On",
        "operator": "Operator",
        "outside": "Outside",
        "position": "Position",
        "time-h": "Hours",
        "time-min": "Minutes",
        "unit": "Unit",
        "value": "Value",
        "weekday": "Weekday",
        "within": "Within"
    },
    "de": {
        "conditionPlaceholder": "Messwert auswählen",
        "customConditionName": "Name",
        "dtcCodesAvailable": "DTC Code vorhanden",
        "eq": "Gleich",
        "fallbackToHistory": "Historische Daten verwenden",
        "gateways": "Gateways",
        "gt": "Grösser als",
        "inside": "Innerhalb",
        "invalidName": "Ungültiger Name",
        "invalidValue": "Ungültiger Wert",
        "location": "Standort",
        "lt": "Kleiner als",
        "maintenanceNeeded": "Wartung ist erforderlich",
        "moreThan": "Mehr als",
        "off": "Aus",
        "on": "Ein",
        "operator": "Bedingung",
        "outside": "Ausserhalb",
        "position": "Position",
        "time-h": "Stunden",
        "time-min": "Minuten",
        "unit": "Einheit",
        "value": "Wert",
        "weekday": "Weekday",
        "within": "Innerhalb von"
    },
    "fr": {
        "conditionPlaceholder": "Choisire une mesure",
        "customConditionName": "Nom",
        "dtcCodesAvailable": "DTC Code disponible",
        "eq": "est égal à",
        "fallbackToHistory": "Utiliser les données historiques",
        "gateways": "Gateways",
        "gt": "Plus grand que",
        "inside": "A l'intérieur de",
        "invalidName": "Nom invalide",
        "invalidValue": "Valeur invalide",
        "location": "Emplacement",
        "lt": "Plus petit que",
        "maintenanceNeeded": "Une maintenance est nécessaire",
        "moreThan": "Plus que",
        "off": "Off",
        "on": "On",
        "operator": "Opérateur",
        "outside": "A l'extérieur de",
        "position": "Position",
        "time-h": "Heures",
        "time-min": "Minutes",
        "unit": "Unité",
        "value": "Valeur",
        "weekday": "Weekday",
        "within": "Dans les"
    },
    "it": {
        "conditionPlaceholder": "Scegliere la misura",
        "customConditionName": "Nome",
        "dtcCodesAvailable": "DTC Code disponibile",
        "eq": "equivale a",
        "fallbackToHistory": "Utilizzare i dati storici",
        "gateways": "Gateways",
        "gt": "maggiore di",
        "inside": "All'interno di",
        "invalidName": "Nome non valido",
        "invalidValue": "Valore non valido",
        "location": "Posizione",
        "lt": "Meno di",
        "maintenanceNeeded": "La manutenzione è necessaria",
        "moreThan": "Più di",
        "off": "Off",
        "on": "On",
        "operator": "Operatore",
        "outside": "All'esterno di",
        "position": "Sosizione",
        "time-h": "Ore",
        "time-min": "Minuti",
        "unit": "Unità",
        "value": "Valore",
        "weekday": "Weekday",
        "within": "Entro"
    }
}
</i18n>

<style lang="scss" scoped>
.conditions-row {
    &:first-child {
        min-width: 60%;
    }
}

.clickable {
    font-size: 14px;
}

.form-error {
    margin-bottom: 0;
}
</style>
