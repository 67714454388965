<template>
    <div class="card">
        <span class="badge">{{ count }}</span>

        <img :src="icon" />
    </div>
</template>

<script>
export default {
    name: 'AssetTypeCountCard',
    props: {
        icon: {
            type: String,
            required: true,
        },
        count: {
            type: Number,
            default: 0,
        },
    },
}
</script>

<style lang="scss" scoped>
.card {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    padding: 4px 12px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);

    .badge {
        display: block;
        width: 24px;
        height: 24px;
        padding: 6px 0;
        margin-top: -16px;
        margin-left: 20px;
        background-color: $color-error;
        border-radius: 50%;
        line-height: 1;
        font-size: 12px;
        color: #fff;
        text-align: center;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
    }

    img {
        width: 24px;
        height: 24px;
    }
}
</style>
