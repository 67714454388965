<template>
    <div class="measurements-filter-modal">
        <BaseHeader :title="$t('title')" @close="$emit('close')" />

        <div
            class="l-padded l-stack l-gap-2 measurements-filter-modal__content"
        >
            <template v-for="(item, i) in items">
                <NotificationConditionForm
                    v-if="editMode && editItem === i"
                    :key="i"
                    class="settings-form"
                    :excluded-measurements="measurementsExcluded"
                    :initial-value="items[editItem]"
                    @close="handleFormClose"
                />

                <NotificationSettingsItem
                    v-else
                    :key="i"
                    :title="getTitle(item)"
                    :description="getDescription(item)"
                    class="clickable"
                    @click="openForm(i)"
                >
                    <template #icon>
                        <MonitorHeartRateIcon width="24" height="24" />
                    </template>

                    <template #iconRight>
                        <a class="clickable" @click.stop="items.splice(i, 1)">
                            <RemoveIcon width="24" height="24" />
                        </a>
                    </template>
                </NotificationSettingsItem>
            </template>

            <transition name="settings-form">
                <NotificationConditionForm
                    v-if="editMode && editItem === null"
                    class="settings-form"
                    :excluded-measurements="measurementsExcluded"
                    @close="handleFormClose"
                />
            </transition>

            <NotificationSettingsItem
                v-if="!editMode"
                class="clickable"
                :title="$t('addFilter')"
                empty
                @click="openForm()"
            >
                <template #icon>
                    <AddIcon width="24" height="24" />
                </template>
            </NotificationSettingsItem>
        </div>

        <footer
            class="l-padded l-inline l-spread l-center-v measurements-filter-modal__actions"
        >
            <span
                class="clickable"
                @click="
                    update([])
                    $emit('close')
                "
            >
                {{ $t('clear') }}
            </span>

            <BaseButton
                :disabled="editMode"
                size="small"
                @click="
                    update(items)
                    $emit('close')
                "
            >
                {{ $t('apply') }}
            </BaseButton>
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import { measurementHelper } from '@/utils'
import AddIcon from '../icons/AddIcon'
import BaseButton from './BaseButton'
import BaseHeader from './BaseHeader'
import MonitorHeartRateIcon from '../icons/MonitorHeartRateIcon'
import NotificationConditionForm from './NotificationConditionForm'
import NotificationSettingsItem from './NotificationSettingsItem'
import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'MeasurementsFilterModal',
    components: {
        AddIcon,
        BaseButton,
        BaseHeader,
        MonitorHeartRateIcon,
        NotificationConditionForm,
        NotificationSettingsItem,
        RemoveIcon,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        update: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            editItem: null,
            editMode: false,
            items: [],
            measurementsExcluded: ['weekday_schedule'],
            operatorTransalations: {
                '<': this.$t('lt'),
                '=': this.$t('eq'),
                '>': this.$t('gt'),
            },
            units: measurementHelper.units,
        }
    },
    computed: {
        ...mapState('location', ['locations']),
    },
    mounted() {
        this.items = this.value
    },
    methods: {
        closeForm() {
            this.editMode = false
            this.editItem = null
        },
        getDescription(item) {
            switch (item.type) {
                case 'geofence':
                    return `${this.$t(item.position)} ${
                        this.locations.find(
                            location => location.id === item.locationId
                        )?.name
                    }`
                case 'maintenance':
                    return this.$t('maintenanceNeeded')
                case 'offline':
                    return this.$t('offlineFor', {
                        amount: item.seconds / 3600,
                        unit: item.user_unit,
                    })
                case 'online':
                    return this.$t('onlineWithin', {
                        amount: item.seconds / 3600,
                        unit: item.user_unit,
                    })
                case 'regex':
                    return item.value === 'battery_status'
                        ? this.$t(
                              'shared.measurementOptions.battery_status.' +
                                  item.regex
                          )
                        : this.$t('regexMatches', [item.value, item.regex])
                case 'state':
                    return `${this.$t(item.value)} ${this.$t(
                        item.state ? 'isOn' : 'isOff'
                    )}`
                default:
                    return this.getDescriptionConditionThreshold(item)
            }
        },
        getDescriptionConditionThreshold(item) {
            const operator = this.operatorTransalations[item.operator] || ''
            const unit = this.units[item.value] || ''
            const value =
                item.regex ||
                (item.value === 'fill_level'
                    ? item.threshold * 100
                    : item.threshold)

            return `${operator} ${value} ${unit}`
        },
        getTitle(item) {
            return item.type === 'geofence' ||
                item.type === 'maintenance' ||
                item.type === 'offline' ||
                item.type === 'online' ||
                item.type === 'state' ||
                (item.type === 'regex' && item.value !== 'battery_status')
                ? this.$t(`shared.measurements.${item.type}`)
                : this.$root.$te(`shared.measurements.${item.value}`)
                ? this.$t(`shared.measurements.${item.value}`)
                : item.value
        },
        handleFormClose(value) {
            if (value) {
                if (this.editItem === null) {
                    this.items.push(value)
                } else {
                    this.items[this.editItem] = value
                }
            }
            this.closeForm()
        },
        openForm(itemIndex = null) {
            this.editMode = true
            this.editItem = itemIndex
        },
    },
}
</script>

<i18n>
{
    "en": {
        "addFilter": "Add filter condition",
        "apply": "Apply",
        "clear": "Clear",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "d3": "Channel 3",
        "d4": "Channel 4",
        "d5": "Channel 5",
        "d6": "Channel 6",
        "delete": "Delete",
        "edit": "Edit",
        "eq": "Equals",
        "gt": "Greater than",
        "inside": "Inside",
        "isOff": "is Off",
        "isOn": "is On",
        "lt": "Less than",
        "maintenanceNeeded": "Maintenance is needed",
        "offlineFor": "for {amount} {unit}",
        "onlineWithin": "within the last {amount} {unit}",
        "outside": "Outside",
        "regexMatches": "{0} matches {1}",
        "title": "Filter by measurements"
    },
    "de": {
        "addFilter": "Filter hinzufügen",
        "apply": "Anwenden",
        "clear": "Löschen",
        "d1": "Kanal 1",
        "d2": "Kanal 2",
        "d3": "Kanal 3",
        "d4": "Kanal 4",
        "d5": "Kanal 5",
        "d6": "Kanal 6",
        "delete": "Löschen",
        "edit": "Bearbeiten",
        "eq": "Gleich",
        "gt": "Grösser als",
        "inside": "Innerhalb",
        "isOff": "aus",
        "isOn": "ein",
        "lt": "Kleiner als",
        "maintenanceNeeded": "Wartung ist erforderlich",
        "offlineFor": "für {amount} {unit}",
        "onlineWithin": "innerhalb der letzten {amount} {unit}",
        "outside": "Ausserhalb",
        "regexMatches": "{0} matches {1}",
        "title": "Filtern nach Messungen"
    },
    "fr": {
        "addFilter": "Ajouter un filtre",
        "apply": "Appliquer",
        "clear": "Effacer",
        "d1": "Canal 1",
        "d2": "Canal 2",
        "d3": "Canal 3",
        "d4": "Canal 4",
        "d5": "Canal 5",
        "d6": "Canal 6",
        "delete": "Supprimer",
        "edit": "Editer",
        "eq": "est égal à",
        "gt": "Plus grand que",
        "inside": "A l'intérieur de",
        "isOff": "est off",
        "isOn": "est on",
        "lt": "Plus petit que",
        "maintenanceNeeded": "Une maintenance est nécessaire",
        "offlineFor": "pour {amount} {unit}",
        "onlineWithin": "au cours des dernières {amount} {unit}",
        "outside": "A l'extérieur de",
        "regexMatches": "{0} matches {1}",
        "title": "Filtrer par mesures"
    },
    "it": {
        "addFilter": "Aggiungere un filtro",
        "apply": "Applicare",
        "clear": "Chiaro",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "d3": "Channel 3",
        "d4": "Channel 4",
        "d5": "Channel 5",
        "d6": "Channel 6",
        "delete": "Cancellare",
        "edit": "Condizione",
        "eq": "è uguale a",
        "gt": "Maggiore di",
        "inside": "All'interno di",
        "isOff": "è off",
        "isOn": "è on",
        "lt": "Meno di",
        "maintenanceNeeded": "La manutenzione è necessaria",
        "offlineFor": "per {amount} {unit}",
        "onlineWithin": "nelle ultime {amount} {unit}",
        "outside": "All'esterno di",
        "regexMatches": "{0} matches {1}",
        "title": "Filtro per misurazioni"
    }
}
</i18n>

<style lang="scss">
.measurements-filter-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-gray-lighter-new;
    font-size: 14px;
    overflow-y: auto;

    &__content {
        flex: 1 0 auto;
        margin-bottom: 68px;
    }

    &__actions {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid $color-gray-light-new;
        background-color: #fff;
    }

    .settings-form {
        width: 100%;
        padding: 24px 16px;
        background-color: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        font-size: 14px;
        color: $color-text-new;
        transition: all 0.2s;

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: translateY(-32px) scale(0.95);
        }
    }

    a {
        svg {
            color: $color-gray-dark-new;
        }
    }
}
</style>
