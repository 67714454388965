<template>
    <BaseMultiselect
        v-model="optionsSelected"
        v-bind="$attrs"
        :options="optionsGrouped"
        track-by="key"
        label="label"
        group-label="group"
        group-values="items"
        :multiple="multiple"
        @input="handleInput"
        @tag="handleTag"
    >
        <template #option="{option}">
            <slot name="option">
                <span :title="option.label || option.$groupLabel">
                    {{ option.label || option.$groupLabel }}
                </span>
            </slot>
        </template>
    </BaseMultiselect>
</template>

<script>
import { mapGetters } from 'vuex'

import BaseMultiselect from './BaseMultiselect'

export default {
    name: 'MeasurementSelect',
    components: {
        BaseMultiselect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: [Array, String],
            default: () => [],
        },
    },
    data() {
        return {
            optionsSelected: [],
        }
    },
    computed: {
        ...mapGetters('tracker', [
            'assetMeasurementLabels',
            'assetTypesById',
            'customerFieldLabels',
        ]),
        optionsGrouped() {
            const groups = [
                {
                    group: this.$t('field'),
                    items: [],
                },
                {
                    group: this.$t('analogChannels'),
                    items: [],
                },
                {
                    group: this.$t('shared.measurements.state'),
                    items: [],
                },
                {
                    group: this.$t('shared.measurements.temperature'),
                    items: [],
                },
                {
                    group: this.$t('shared.measurements.voltage'),
                    items: [],
                },
            ]

            const assetMeasurements = {}
            const customerFields = {}

            this.optionsMapped.forEach(option => {
                let groupIndex = 0
                if (/^ai\d$/.test(option.key)) {
                    groupIndex = 1
                } else if (/^(d\d|sensor_attached)$/.test(option.key)) {
                    groupIndex = 2
                } else if (/^t\d$/.test(option.key)) {
                    groupIndex = 3
                } else if (/^voltage_\d$/.test(option.key)) {
                    groupIndex = 4
                } else if (option.key in this.assetMeasurementLabels) {
                    const [, assetType] = option.key.split(':')
                    const group = this.$t('assetMeasurements', [
                        this.assetTypesById[assetType].name,
                    ])
                    assetMeasurements[group] = assetMeasurements[group]
                        ? assetMeasurements[group].concat(option)
                        : [option]
                } else if (option.key in this.customerFieldLabels) {
                    const [, assetType] = option.key.split(':')
                    const group = this.$t('customerFields', [
                        this.assetTypesById[assetType].name,
                    ])
                    customerFields[group] = customerFields[group]
                        ? customerFields[group].concat(option)
                        : [option]
                }
                groups[groupIndex].items.push(option)
            })

            return [
                ...groups,
                ...Object.entries(assetMeasurements)
                    .sort(([a], [b]) => a.localeCompare(b))
                    .map(([group, items]) => ({
                        group,
                        items,
                    })),
                ...Object.entries(customerFields)
                    .sort(([a], [b]) => a.localeCompare(b))
                    .map(([group, items]) => ({
                        group,
                        items,
                    })),
            ]
        },
        optionsMapped() {
            return this.options
                .map(option => ({
                    key: option,
                    label:
                        this.assetMeasurementLabels[option] ??
                        this.customerFieldLabels[option] ??
                        (this.$te(option)
                            ? this.$t(option)
                            : this.$root.$te(`shared.measurements.${option}`)
                            ? this.$t(`shared.measurements.${option}`)
                            : option),
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                if (!this.value) {
                    this.optionsSelected = []
                } else if (Array.isArray(this.value)) {
                    this.optionsSelected =
                        this.value.map(
                            item =>
                                this.optionsMapped.find(
                                    option => option.key === item
                                ) || {
                                    key: item,
                                    label: item,
                                }
                        ) || []
                } else {
                    this.optionsSelected =
                        this.optionsMapped.find(
                            option => option.key === this.value
                        ) || []
                }
            },
        },
    },
    methods: {
        handleInput(value) {
            this.$emit(
                'input',
                Array.isArray(value) ? value.map(item => item.key) : value?.key
            )
        },
        handleTag(option) {
            if (!this.optionsSelected?.length) {
                this.optionsSelected = [
                    {
                        key: option,
                        label: option,
                    },
                ]
            } else if (
                !this.optionsSelected.find(item => item.key === option)
            ) {
                this.optionsSelected.push({
                    key: option,
                    label: option,
                })
            } else {
                return
            }

            this.handleInput(this.optionsSelected)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "analogChannels": "Analog channels",
        "assetMeasurements": "{0}'s custom measurements",
        "current_locations": "Current locations",
        "customerFields": "{0}'s customer defined fields",
        "deveui": "DevEUI",
        "field": "Field",
        "identifier": "Identifier",
        "last_contact": "Last contact",
        "last_gps_measurement": "Last GPS measurement",
        "location": "Location",
        "maintenance": "Maintenance",
        "model": "Model",
        "rssi": "RSSI",
        "snr": "SNR",
        "weekday_schedule": "Weekday schedule"
    },
    "de": {
        "analogChannels": "Analoge Kanäle",
        "assetMeasurements": "Benutzerdefinierte Messungen von {0}",
        "current_locations": "Aktuelle Standorte",
        "customerFields": "Benutzerdefinierte Felder von {0}",
        "deveui": "DevEUI",
        "field": "Feld",
        "identifier": "Identifikation",
        "last_contact": "Letzter Kontakt",
        "last_gps_measurement": "Letzte GPS Messung",
        "location": "Standort",
        "maintenance": "Wartung",
        "model": "Modell",
        "rssi": "RSSI",
        "snr": "SNR",
        "weekday_schedule": "Zeitplan"
    },
    "fr": {
        "analogChannels": "Canaux analogiques",
        "assetMeasurements": "Mesures personnalisées de {0}",
        "current_locations": "Emplacements actuels",
        "customerFields": "Champs définis par le client de {0}",
        "deveui": "DevEUI",
        "field": "Champ",
        "identifier": "Idantifiant",
        "last_contact": "Dernier contact",
        "last_gps_measurement": "Dernière mesure GPS",
        "location": "Emplacement",
        "maintenance": "Maintenance",
        "model": "Modèle",
        "rssi": "RSSI",
        "snr": "SNR",
        "weekday_schedule": "Horaire"
    },
    "it": {
        "analogChannels": "Canali analogici",
        "assetMeasurements": "Misure personalizzate di {0}",
        "current_locations": "Luoghi attuali",
        "customerFields": "Campi definiti dal cliente di {0}",
        "deveui": "DevEUI",
        "field": "Campo",
        "identifier": "Identifier",
        "last_contact": "Ultimo Contatto",
        "last_gps_measurement": "Ultimo Misurazione del GPS",
        "location": "Locazione",
        "maintenance": "Manutenzione",
        "model": "Modello",
        "rssi": "RSSI",
        "snr": "SNR",
        "weekday_schedule": "Orario"
    }
}
</i18n>
