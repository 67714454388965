<template>
    <div
        class="dashboard-assets-view"
        :class="{
            'dashboard-assets-view--splitted': selected.length,
            'dashboard-assets-view--mobile-active': isEditing,
        }"
    >
        <div class="dashboard-assets-view__filters">
            <BaseInput
                v-model="filterSearch"
                :debounce="400"
                :placeholder="$t('filterByQuery')"
                block
            >
                <template #icon>
                    <SearchIcon width="16" height="16" />
                </template>
            </BaseInput>

            <BaseMultiselect
                v-model="filterType"
                :placeholder="$t('filterByType')"
                :options="assetTypes"
                track-by="id"
                label="name"
                multiple
                block
            />

            <BaseMultiselect
                v-model="filterLocation"
                :placeholder="$t('filterByLocation')"
                :options="locationsSortedByName"
                track-by="id"
                label="name"
                multiple
                block
            />

            <ColorInput v-model="filterColor" class="redesigned" />

            <BaseButton size="small" @click="openMeasurementsFilterModal">
                {{ $t('filterByMeasurements') }}
                <span v-if="filterMeasurement.length" class="badge">
                    {{ filterMeasurement.length }}
                </span>
            </BaseButton>

            <BaseButton
                v-if="hasSharingAccess"
                :disabled="!selected.length"
                size="small"
                @click="openAssetSharingModal"
            >
                {{ $t('share') }}
            </BaseButton>
        </div>

        <div class="dashboard-assets-view__split">
            <DashboardAssetsTableView
                class="dashboard-assets-view__table"
                :selected.sync="selected"
                :filter-color="filterColor"
                :filter-location="filterLocation"
                :filter-measurement="filterMeasurement"
                :filter-search="filterSearch"
                :filter-type="filterType"
                @clearFilter="clearFilter"
                @click="isEditing = true"
            />

            <template v-if="selected.length">
                <span
                    :class="[
                        'dashboard-assets-view__split-icon',
                        {
                            ['dashboard-assets-view__split-icon--offset']: !isEditing,
                        },
                    ]"
                >
                    <IconButton @click="isEditing = !isEditing">
                        <RemoveIcon v-if="isEditing" width="18" height="18" />
                        <PencilIcon v-else width="18" height="18" />
                    </IconButton>
                </span>

                <DashboardAssetsEditView
                    class="dashboard-assets-view__form"
                    :asset-ids="selected"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AssetSharingModal from '../redesigned/AssetSharingModal'
import BaseButton from '../redesigned/BaseButton'
import BaseInput from '../redesigned/BaseInput'
import BaseMultiselect from '../redesigned/BaseMultiselect'
import ColorInput from '../ColorInput'
import DashboardAssetsEditView from './DashboardAssetsEditView'
import DashboardAssetsTableView from './DashboardAssetsTableView'
import IconButton from '../IconButton'
import MeasurementsFilterModal from '../redesigned/MeasurementsFilterModal'
import PencilIcon from '../icons/PencilIcon'
import RemoveIcon from '../icons/RemoveIcon'
import SearchIcon from '../icons/SearchIcon'

export default {
    name: 'DashboardAssetsView',
    components: {
        BaseButton,
        BaseInput,
        BaseMultiselect,
        ColorInput,
        DashboardAssetsEditView,
        DashboardAssetsTableView,
        IconButton,
        PencilIcon,
        RemoveIcon,
        SearchIcon,
    },
    data() {
        return {
            selected: [],
            filterColor: [],
            filterLocation: [],
            filterMeasurement: [],
            filterSearch: '',
            filterType: [],
            isEditing: false,
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes']),
        ...mapGetters('auth', ['hasSharingAccess']),
        ...mapGetters('location', ['locationsSortedByName']),
    },
    watch: {
        filterColor() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    color: this.filterColor,
                },
            })
        },
        filterLocation() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    location: this.filterLocation.map(item => item.id),
                },
            })
        },
        filterMeasurement() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    measurement: this.filterMeasurement,
                },
            })
        },
        filterSearch() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    search: this.filterSearch || undefined,
                },
            })
        },
        filterType() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    type: this.filterType.map(item => item.id),
                },
            })
        },
    },
    mounted() {
        if (this.$route.query.color) {
            this.filterColor = [].concat(this.$route.query.color)
        }

        if (this.$route.query.location) {
            const ids = [].concat(this.$route.query.location).map(Number)
            this.filterLocation = this.locationsSortedByName.filter(item =>
                ids.includes(item.id)
            )
        }

        if (this.$route.query.measurement) {
            this.filterMeasurement = [].concat(this.$route.query.measurement)
        }

        if (this.$route.query.search) {
            this.filterSearch = this.$route.query.search
        }

        if (this.$route.query.type) {
            const ids = [].concat(this.$route.query.type).map(Number)
            this.filterType = this.assetTypes.filter(item =>
                ids.includes(item.id)
            )
        }
    },
    methods: {
        clearFilter() {
            this.filterColor = []
            this.filterLocation = []
            this.filterMeasurement = []
            this.filterSearch = ''
            this.filterType = []
        },
        handleMeasurementsFilter(filters = []) {
            this.filterMeasurement = filters.map(item => {
                switch (item.type) {
                    case 'geofence':
                        return `${item.type}${
                            item.position === 'inside' ? '=' : '>'
                        }${item.locationId}`
                    case 'maintenance':
                        return `${item.type}=true`
                    case 'offline':
                    case 'online':
                        return `${item.type}>${item.seconds}`
                    case 'regex':
                        return `${item.type}=${item.value}=${item.regex}`
                    case 'state':
                        return `${item.type}=${item.value}=${item.state}`
                    default:
                        return `${item.value}${item.operator}${item.threshold}`
                }
            })
        },
        openAssetSharingModal() {
            this.$modal.show(
                AssetSharingModal,
                { assetIds: this.selected },
                { width: 400, height: 460 }
            )
        },
        openMeasurementsFilterModal() {
            const value = this.filterMeasurement.map(item => {
                const [measurement, value, subvalue] = item.split(/[<=>]/)

                switch (measurement) {
                    case 'geofence':
                        return {
                            locationId: +value,
                            position:
                                item.slice(measurement.length)[0] === '='
                                    ? 'inside'
                                    : 'outside',
                            type: measurement,
                        }
                    case 'maintenance':
                        return {
                            ahead_value: 0,
                            type: measurement,
                        }
                    case 'offline':
                    case 'online':
                        return {
                            seconds: value,
                            type: measurement,
                            user_unit: 'h',
                        }
                    case 'regex':
                        return {
                            regex: subvalue,
                            type: measurement,
                            value,
                        }
                    case 'state':
                        return {
                            state: subvalue,
                            type: measurement,
                            value,
                        }
                    default:
                        return {
                            operator: item.slice(measurement.length)[0],
                            threshold: value,
                            type: 'threshold',
                            value: measurement,
                        }
                }
            })

            this.$modal.show(
                MeasurementsFilterModal,
                { value, update: this.handleMeasurementsFilter },
                { width: 400, height: '75%' }
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "filterByColor": "Color",
        "filterByLocation": "Location",
        "filterByMeasurements": "Filter by measurements",
        "filterByQuery": "Name",
        "filterByType": "Type",
        "share": "Share"
    },
    "de": {
        "filterByColor": "Farbe",
        "filterByLocation": "Standort",
        "filterByMeasurements": "Filtern nach Messungen",
        "filterByQuery": "Name",
        "filterByType": "Typ",
        "share": "Teilen"
    },
    "fr": {
        "filterByColor": "Couleur",
        "filterByLocation": "Emplacement",
        "filterByMeasurements": "Filtrer par mesures",
        "filterByQuery": "Nom",
        "filterByType": "Type",
        "share": "Partager"
    },
    "it": {
        "filterByColor": "Colore",
        "filterByLocation": "Locazione",
        "filterByMeasurements": "Filtro per misurazioni",
        "filterByQuery": "Name",
        "filterByType": "Typo",
        "share": "Condividi"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-assets-view {
    $self: &;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1.5rem 1rem 0.5rem 2rem;
        background-color: $color-gray-lighter-new;
        border-bottom: $style-border;

        & > * {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .base-input,
        .multiselect {
            max-width: 200px;
        }

        .base-button {
            padding: 10px 16px;
            background-color: #fff;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            line-height: 20px;
            font-weight: 600;
            font-size: 15px;
            color: $color-primary-text;

            &:disabled {
                color: rgba($color-primary-text, 0.25);
            }
        }

        .badge {
            display: inline-block;
            margin-left: 6px;
            padding: 0 8px;
            background-color: $color-accent;
            color: #fff;
            border-radius: 17px;
            line-height: 17px;
            font-weight: 600;
            font-size: 13px;
        }
    }

    &__split {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        & > div {
            width: 375px;
            height: 100%;
            overflow-y: auto;
        }

        #{$self}__table {
            width: 100%;
        }
    }

    &__split-icon {
        display: none;
        position: absolute;
        padding: 1rem;
        top: 0;
        right: 0;
        margin: 0 0.5rem;
        z-index: 2;

        &--offset {
            right: 4rem;
            margin: 0;
        }
    }

    &--splitted {
        #{$self}__table {
            width: calc(100% - 375px);
        }

        #{$self}__form {
            border-left: $style-border;
        }
    }

    @include respond-to('for-mobile-down') {
        &__split {
            flex-direction: column-reverse;

            & > div {
                width: 100% !important;
                height: auto;
                border-left: none !important;
                flex: 1 0 100%;
            }
        }

        &__split-icon {
            display: inline-block;
        }

        &--splitted {
            #{$self}__form {
                display: none;
            }

            &#{$self}--mobile-active {
                #{$self}__filters {
                    display: none;
                }

                #{$self}__split {
                    height: auto;
                    overflow-y: initial;
                }

                #{$self}__table {
                    display: none;
                }

                #{$self}__form {
                    display: block;
                }
            }
        }
    }

    @include respond-to('for-small-mobile-only') {
        &__filters {
            padding: 1rem 1rem 0;

            & > * {
                &:not(.base-button) {
                    margin-right: 0;
                }
            }

            .base-input,
            .multiselect {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
</style>
